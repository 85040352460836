@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,600,700');

$orange: #ef731b;
$bg: #FEFCF9;
$font-color: #404040;

/* Space out content a bit */
body {
  padding-top: 1.5rem;
  font-family: 'Raleway', Arial, sans-serif;
  background: $bg;
  letter-spacing: 0.04em;
	color: $font-color;
	-webkit-font-smoothing: antialiased;

}

h3 {
	letter-spacing: 0;
}

img {
	max-width: 100%;
}

/* Everything but the jumbotron gets side spacing for mobile first views */
.header,
.marketing {
  padding-right: 1rem;
  padding-left: 1rem;
}

/* Custom page header */
.header {
  padding-bottom: 1rem;
  border-bottom: .05rem solid #e5e5e5;
}
/* Make the masthead heading the same height as the navigation */
.header h3 {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 3rem;
}

.header_logo {
	max-width: 300px;
}

.alert-custom {
	border: none;
	text-align: center;
	border-radius: 5px;
	padding: 20px 40px 40px 40px;
	background: white;
	.alert-heading {
		padding: 20px 0;
	}


}
/* Custom page footer */
.footer {
  /*
  padding-top: 1.5rem;
  color: #777;
  border-top: .05rem solid #e5e5e5;*/
  text-align: center;
  border-top: 0.5px solid white;
  padding: 1em;
  min-height: 100px;
}
.nav-item {
	padding: 20px;
	a {
		display: inline-block;
		height: 100%;
		width: 100%;
		text-decoration: none;
	}

  &.lang {
	  margin: 20px 10px;
	  background-repeat: no-repeat;
	  background-size: 29px auto;
	  background-position: center center;
	  border-radius: 50%;
	  border: 1px solid #ef731b;
	  width: 26px;
	  height: 26px;
	  padding: 0;

    @media (max-width: 992px) {
      background-position: center left;
      width: inherit;
      padding:5px ;
      margin: 0 5px;
      a {
        width: 100%;
        height: 50px!important;
        line-height: 20px;
      }
      .sr-only {
        padding-left: 30px;
        position: static;
        overflow:visible;
        width: 100%;
        background: none!important;
      }
    }

    &:hover {

      transition: ease all 0.2s;

      a {
        background: none;

      }

    }
    a {
      width: 22px;
      height: 22px;
      padding:0;
      margin: 0;

    }
    &.polish {
      background-image:  url("/assets/images/pl.png");
    }
    &.icelandic {
      background-image:  url("/assets/images/is.png");
    }
    &.english {
      background-image:  url("/assets/images/gb.png");
    }
    &:hover {
      border: 2px solid darken($orange,10);
    }
  }
	&.logout{
		border-radius: 5px;
		position: relative;
		padding-right: 50px;
		margin-right: 10px;
		transition: ease 0.2 all;
		&:after {
			position: absolute;
			content: "";
			width: 30px;
			height: 30px;
			top: 18px;
			right: 10px;
			background-image: url("/assets/images/icon-logout.png");
			background-repeat: no-repeat;
			background-position: center  right;
			background-size: 20px auto;
		}
		&:hover {
			background: darken($bg, 3);
		}
	}
}
hr {
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0em;
  margin-left: auto;
  margin-right: auto;
  border-top: 0,85px solid #8c8b8b;
  border-width: 1px;
}

.btn {
	white-space: pre-wrap;;
	&.btn-lg {
		padding: 1rem 2rem;
		font-size: 1.2rem;
		line-height: 1.5;
		border-radius: 0;

	}
	&.btn-default {
		border: 2px solid $orange;
		background: white;
		color: $orange;
		&:hover {
			background: $orange;
			color: white;
		}
	}
	.login-icon {
		fill: $orange;
		margin-left: -20px;
		padding-right: 20px;
		transform: translateX(-10px);
	}
	&.btn-icekey {
		background: #27589a;
		border-radius: 8px!important;
		color: white;
		max-width: 100%;
		word-wrap: break-word;
		position: relative;
		padding-left: 3rem;
		min-height: 86px;
		@media screen and (max-width: 900px) {
			line-height: 1.2em;
		}
		img {
			position: absolute;
			left: 16px;
			top: 10px;
			max-height: 100%;
		}
	}
}
.form-fields {
	text-align: center;
}

/* Customize container */
/*
@media (min-width: 48em) {
  .container {
    max-width: 46rem;
  }
}
.container-narrow > hr {
  margin: 2rem 0;
}


.container {

}
*/
/* Main marketing message and sign up button */
.jumbotron {
  text-align: center;
  border-bottom: .05rem solid #e5e5e5;
}
.jumbotron .btn {
  padding: .75rem 1.5rem;
  font-size: 1.5rem;
}

/* Supporting marketing content */
.marketing {
  margin: 3rem 0;
}
.marketing p + h4 {
  margin-top: 1.5rem;
}

ul {
	li {
		line-height: 1.8em;
	}
}



/* Responsive: Portrait tablets and up */
@media screen and (min-width: 48em) {
  /* Remove the padding we set earlier */
  .header,
  .marketing,
  .footer {
    padding-right: 0;
    padding-left: 0;
  }
  /* Space out the masthead */
  .header {
    margin-bottom: 2rem;
  }
  /* Remove the bottom border on the jumbotron for visual effect */
  .jumbotron {
    border-bottom: 0;
  }
}
.alert-success {

}
.footer {
	background: #727272;
	.logos {
		margin: 40px 20px;
		opacity: 0.8;
		.col {
			text-align: center;
		}
	}
}
.subfooter {
	background: #5e5e5e;
	padding: 25px 0;
	color: white;
	font-size: 12px;
	letter-spacing: 1px;

	span {
		opacity: 0.6;
	}
}
.content-holder {
	padding: 40px 10% 40px 10%;
	background: white;
	border-radius: 4px;
	border: 1px solid darken($bg,5);
	h3 {
		padding-top: 40px;
		color: $orange;
		font-size: 2.2em;
	}
	h4 {
		padding-top: 40px;
		color: $orange;
		font-size: 2em;
	}
	p {
		font-size: 1.2em;
		line-height: 1.7em;
		strong {
			font-weight: 600;
			color: $orange;
		}
	}

	input[type="checkbox"] {
		display:none;
	}

	input[type="checkbox"] + label {
		font-weight: 600;
	}

	.checkbox-holder {
		padding: 20px;
		border-radius: 2px;
		background: #fcfcfc;
		label {
			color:$orange;
			font-weight: 600;
		}
		ul {
			li {
				max-width: 740px;
				font-size: 1.2em;
				margin-bottom: 20px;
				line-height: 1.6em;
			}
		}
	}
	/* Custom checkbox */
	.container-checkbox {
		display: block;
		position: relative;
		padding-left: 60px;
		cursor: pointer;
		font-size: 22px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		margin-bottom: 30px;
		line-height: 2em;
		@media screen and (max-width: 900px) {
			line-height: 1.2em;
		}
			input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
		}
	}

	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 42px;
		width: 42px;
		background-color: #eee;
		border: 2px solid $orange;
		border-radius: 2px;
	}


	.checkmark:after {
		content: "";
		position: absolute;
		display: none;
	}

	.container-checkbox {
		&:hover {
			.checkmark {
				background-color: #ececec;
				&:after {
					display: block;
					left: 12px;
					top: 3px;
					width: 16px;
					height: 25px;
					border: solid rgba($orange,0.2);
					border-width: 0 6px 6px 0;
					-webkit-transform: rotate(45deg);
					-ms-transform: rotate(45deg);
					transform: rotate(45deg);
				}
			}
		}
		.checkmark:after {
			left: 12px;
			top: 3px;
			width: 16px;
			height: 25px;
			border: solid $orange;
			border-width: 0 6px 6px 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
		input:checked {
			~.checkmark {
				.container-checkbox input:checked ~ .checkmark {
					background-color: #eeeeee;
				}
				&:after {
					display: block;
					border: solid rgba($orange,1)!important;
					border-width: 0 6px 6px 0!important;


				}
			}
		}
	}

}
.radio-communication {
	padding: 40px;
	small {
		opacity: 0.6;
	}

}

.inner-title {
	color: $orange;
	font-size: 20px;
	margin-top: 40px;
}

/* Custom checkbox */
.container-radiobutton {
	display: block;
	position: relative;
	padding-left: 60px;
	cursor: pointer;
	font-size: 18px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	margin-bottom: 30px;
	line-height: 2em;
	&:hover {
		.radio {
			background-color: #ececec;
			&:after {
				display: block;
				left: 6px;
				top: 6px;
				width: 16px;
				height: 16px;
				border: solid rgba($orange,0.2) 10px;
				border-radius: 50%;
			}
		}
	}
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		&:checked {
			~ .radio {
				.container-radio input:checked ~ .checkmark {
					background-color: #eeeeee;
				}
				&:after {
					display: block;
					border: solid rgba($orange, 1) 10px !important;

				}
			}
		}
	}
	.radio {
		position: absolute;
		top: 0;
		left: 0;
		height: 36px;
		width: 36px;
		background-color: #eee;
		border: 2px solid $orange;
		border-radius: 50%;
		&:after {
			content: "";
			position: absolute;
			display: none;
			left: 6px;
			top: 6px;
			width: 16px;
			height: 16px;
			border: solid $orange;
			border-radius: 50%;
			border-width: 10px;

		}
	}
}


.form-holder-login {
	padding: 40px 10%;
	background: white;
	border-radius: 4px;
	border: 1px solid darken($bg,5);
	.form-group {
		max-width: 300px;
		margin: 10px auto;
	}
}

.islogin-description {
	font-size: 1.2em;
	padding: 20px;
	font-weight: 500;
	-webkit-font-smoothing: antialiased;

}
.form-group {
  input[type=text],input[type=password] {
	  border: 2px solid $orange;
  }


  .email-addr {
    transition: ease 0.2s all;
    position: relative;


  }
  .notvalid {
    border: 1px solid #FFEB3B;
    color: black;
    font-weight: bold;
    background: #FDD75F url("/assets/images/icon-sad.png") no-repeat right 10px center;
    background-size: 24px auto;

  }
  .valid {
    border: 1px solid #4CAF50;
    color: white;
    font-weight: bold;
    background: #4CAF50 url("/assets/images/icon-happy.png") no-repeat right 10px center;
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
    background-size: 24px auto;
  }
}
@-webkit-keyframes autofill {
  to {
    color: white;
    background: #4CAF50 url("/assets/images/icon-happy.png") no-repeat right 10px center;
    background-size: 24px auto;

  }
}
